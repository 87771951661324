<script>
var debugMessage = "";

const usercourse = new Vue({
    el: "#usercourse",
    data: {
        course: "",
        codes: "",
        price: 0,
        validMSG: "16碼",
        errorMSG: {},
    },
    methods: {
        validCodes: function () {
            //userregister.units = {};

            let tcode = usercourse.codes.trim().toUpperCase();
            if (tcode.length == 16) {
                axios
                    .get("/api/code/" + tcode)
                    .then(function (response) {
                        if (response.data["price"] == "undefined") {
                            usercourse.validMSG = "無效的授權碼";
                        } else {
                            if (response.data.price.length == 0) {
                                usercourse.price = 0;
                                usercourse.validMSG = "無效的授權碼";
                            } else {
                                usercourse.price = response.data.price;
                                usercourse.validMSG = response.data.price;
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                usercourse.validMSG = "無效的授權碼";
            }
        },
    },
    created: function () {},
    updated() {
        /*
        if (Vue.config.debug == true) {
            console.log("Objects: " + debugMessage);
        }
        */
    },
    watch: {
        // organize is organiaze ID
        /*
        learnerid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                email = newVal + "@me.mcu.edu.tw";
            }
        },
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {
        //this.getOrganizeList();
    },
});
</script>
