<script>
var debugMessage = "";

const usersuvery = new Vue({
    el: "#suvery_mcu2020",
    data: {
        quiz: {},
        step: 1,
        progBarWidth: "5",
        errorMSG: {},
    },
    methods: {
        prevStep: function () {
            if (this.step > 1) {
                this.step = this.step - 1;
                this.progBarWidth = 25 * this.step;
            }
            if (this.step == 1) {
                $("#prevBtn").hide();
                this.progBarWidth = 5;
            }

            $("#qgroup1").hide();
            $("#qgroup2").hide();
            $("#qgroup3").hide();
            $("#qgroup4").hide();

            $(".progress-bar")
                .css("width", this.progBarWidth + "%")
                .attr("aria-valuenow", this.progBarWidth)
                .html(this.progBarWidth + "%");

            var open = "#qgroup" + this.step;
            $(open).show();
            console.warn("Step :" + this.step);
        },
        nextStep: function () {
            if (this.step < 5) {
                var flag = 0;
                switch (this.step) {
                    case 1:
                        for (let index = 1; index <= 5; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }

                        break;
                    case 2:
                        for (let index = 6; index <= 11; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 3:
                        for (let index = 12; index <= 17; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 4:
                        for (let index = 18; index <= 23; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                                this.quiz["qz24"] = $("#qz24").val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;

                    default:
                        location.replace("/mcu2020");
                        return 0;
                }
                if (flag == 0) {
                    $("#prevBtn").show();
                    $("#qgroup1").hide();
                    $("#qgroup2").hide();
                    $("#qgroup3").hide();
                    $("#qgroup4").hide();
                    this.progBarWidth = 25 * this.step;
                    $(".progress-bar")
                        .css("width", this.progBarWidth + "%")
                        .attr("aria-valuenow", this.progBarWidth)
                        .html(this.progBarWidth + "%");
                    this.step = this.step + 1;
                    var open = "#qgroup" + this.step;
                    $(open).show();
                    if (this.step == 5) {
                        $(".suvery-desc").addClass("text-center m-5");
                        $(".suvery-desc").html("<h4>上傳中請稍後...</h4>");
                        $("#prevBtn").show();
                        $("#nextBtn").show();
                        //console.log(this.quiz);
                        axios
                            .post("/survey-post/mcu2020", this.quiz)
                            .then((response) => {
                                //console.log(response.data);

                                if (response.data.state == 1) {
                                    $(".suvery-desc").html(
                                        "<h4>" + response.data.message + "</h4>"
                                    );
                                } else {
                                    $(".suvery-desc").html(
                                        "<h4>" + response.data.message + "</h4>"
                                    );
                                }
                                setTimeout(
                                    () => location.replace("/panel"),
                                    1500
                                );
                            })
                            .catch(function (error) {
                                $(".suvery-desc").html(
                                    "<h4>處理期間發生錯誤, 請聯絡 <a href='mailto://info@winhoe.com>畹禾客服</a></h4>"
                                );
                            });
                    }
                }
            }

            console.warn("Step :" + this.step);
        },
    },
    created: function () {
        //step = 1 with hide anothers
        $("#qgroup1").show();
        $("#qgroup2").hide();
        $("#qgroup3").hide();
        $("#qgroup4").hide();
        $("#prevBtn").hide();
        $("#exter").empty();
    },
    updated() {
        if (Vue.config.debug == true) {
            console.log("Objects: " + errorMSG);
        }
    },
    watch: {
        // organize is organiaze ID
        /*
        step: (newVal, oldVal) => {
            if (newVal != oldVal) {
                email = newVal + "@me.mcu.edu.tw";
            }
        },
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {
        //this.getOrganizeList();
    },
});
</script>
