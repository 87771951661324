<script>
var debugMessage = "";

const userregister = new Vue({
    el: "#userregister",
    data: {
        /**
         *? The Global Vars from Form
         */
        learnerid: "",
        email: "",
        preid: "",
        prefix: "",
        registerMethod: "",
        remember: false,
        username: "",
        password: "",
        passwordConfirm: "",
        errorMSG: {}
    },
    methods: {
        setPrefix: function(event) {
            if (event.target.options.selectedIndex > -1) {
                const theTarget =
                    event.target.options[event.target.options.selectedIndex]
                        .dataset;
                this.prefix = theTarget.prefix;
            }
        },
        getOrganize: function(newVal) {
            //bug fix for WHC id is 0
            //console.log(newVal);
            if (newVal == 0) {
                newVal = "'0'";
            }
            axios
                .get("/api/org/signup/" + newVal)
                .then(function(response) {
                    if (response.data[0].signup_custom !== null) {
                        $("#mmodalLabel").html("Notice");
                        $("#mmodalContent").html(
                            response.data[0].signup_custom
                        );
                        $("#mmodal").modal("show");
                    }
                    debugMessage =
                        "Update Organize Data successed. [getOrganize]";
                })
                .catch(function(error) {
                    console.log(error);
                    debugMessage =
                        "Update failed. [getOrganize].\r\nloaded empty.\r\n";
                });

            return;
        }
    },
    created: function() {
        //this.getOrganize();
        if ($("#organize option").length == 2) {
            $("#organize")[0].selectedIndex = 1;
            this.getOrganize(this.preid);
        }
        if ($("#organize").val() !== null) {
            this.preid = $("#organize").val();
            this.prefix = $("#organize")
                .find(":selected")
                .data("prefix");
        }
    },
    updated() {
        if (Vue.config.debug == true) {
            console.log("Objects: " + debugMessage);
        }
    },
    watch: {
        // organize is organiaze ID
        learnerid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                email = newVal + "@me.mcu.edu.tw";
            }
        },
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
    },
    mounted() {
        //this.getOrganizeList();
    }
});
</script>
