<script>
var debugMessage = "";

const declar = new Vue({
    el: "#declar",
    props: [

    ],
    data: {
        stuGroup: '',
        stuData: [{ name: '', sid: '' }],
        errorMSG: "",
    },
    methods: {
        purchase: function (priVal) {
            //console.log(priVal);
            window.location.href = '/guide/';
                return;
        },
        /*
        PE34: function (priVal) {
            console.log("PE34");
        },
        PE56: function (priVal) {
            console.log("PE56");
        },
        PE78: function (priVal) {
            console.log("PE78");
        }
        */
    },
    created: function () {
        //step = 0 with hide anothers



    },
    updated() {


        if (Vue.config.debug == true) {
            if (errorMSG !== underfined)
                console.log("Objects: " + errorMSG);
        }
        console.log

    },
    watch: {
        // organize is organiaze ID
        /*
        step: (newVal, oldVal) => {
            if (newVal != oldVal) {
                email = newVal + "@me.mcu.edu.tw";
            }
        },
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {
        //this.getOrganizeList();
    },
});
</script>
