<script>
let debugMessage = "";
const usergroup = new Vue({
    el: "#usergroup",
    data: {
        multiGroup: false,
        group: 0,
        title: "",
        groupL1: "",
        groupL1Title: "",
        groupL1node: {},
        groupL2: "",
        groupL2Title: "",
        groupL2node: {},
        groupL3: "",
        groupL3Title: "",
        groupL3node: {},
        tempNode: {}
    },
    methods: {
        setLevel: function(id, title, tier) {
            switch (tier) {
                case 1:
                    this.group = null;
                    this.groupL1 = id;
                    this.groupL1Title = title;
                    this.groupL2 = "";
                    this.groupL2Title = "";
                    this.groupL2node = null;
                    this.groupL3 = "";
                    this.groupL3Title = "";
                    this.groupL3node = null;
                    this.getNode(id, 2);
                    break;
                case 2:
                    this.group = null;
                    this.groupL2 = id;
                    this.groupL2Title = title;
                    this.groupL3 = "";
                    this.groupL3Title = "";
                    this.groupL3node = null;
                    this.getNode(id, 3);
                    break;
                case 3:
                    this.groupL3 = id;
                    this.groupL3Title = title;
                    this.getNode(id, 4);
                    break;
                default:
                    return false;
                    break;
            }
            debugMessage = "Tier was changed. [setLevel]";
        },
        getNode: function(newVal, storage) {
            this.NewPID = newVal;
            axios
                .get("/group/get/" + newVal)
                .then(function(response) {
                    switch (storage) {
                        case 1:
                            usergroup.groupL1node = response.data;
                            break;
                        case 2:
                            usergroup.groupL2node = response.data;
                            break;
                        case 3:
                            usergroup.groupL3node = response.data;
                            break;
                        case 4:
                            usergroup.groupL4node = response.data;
                            break;
                        default:
                            usergroup.groupL2node = response.data;
                            break;
                    }
                    //console.log(response.data);
                    debugMessage = "Update method successed. [getNode]";
                })
                .catch(function(error) {
                    //userlogin.currentOrganize = {};
                    debugMessage =
                        "Update failed. [getNode].\r\nloaded default data.\r\n";
                });

            return;
        },
        setOptions(e) {
            this.group = [parseInt(e.target.value)];
            this.title = e.target.nextSibling.data.trim();
            //for mcu
            this.groupL3 = "";
            this.groupL3Title = "";

            this.groupL3node = null;
        }
    },
    created: function() {},
    updated() {
        if (Vue.config.debug == true) {
            console.log("Objects: " + debugMessage);
        }
    },
    watch: {
        group: (newVal, oldVal) => {
            console.log("Your choose is " + newVal);
        }
    },
    mounted() {
        /*
            if (searchParams.get("prefix") != false) {
                prefix = searchParams.get("prefix");
                console.log(prefix);
                console.log($("#organization select").data("key"));
             }
        */
    }
});
</script>
