<script>
let debugMessage = "";
let searchParams = new URLSearchParams(window.location.search);
if (document.getElementById("usergroup")) {
    const usergroup = new Vue({
        el: "#usergroup",
        data: {
            /**
             *? The Global Vars from Form
             */
            tmp: {},
            errorMSG: {}
        },
        methods: {
            login: function() {},

            setLoginMethod: function(val) {
                usergroup.id = val;
            },
            setPreIDAppend: function(newVal) {}
        },

        created: function() {
            /*
        Method For Hash Tag
        if (window.location.hash) {
            var prefix = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            this.getOrganizeList(prefix);
        } else {
            this.getOrganizeList();
        }
        */
            //this.getOrganizeList();
        },

        updated() {
            if (Vue.config.debug == true) {
                console.log("Objects: " + debugMessage);
            }
        },
        watch: {
            /*
        preid: (newVal, oldVal) => {
            if (oldVal == "") {
                userlogin.setPreIDAppend(newVal);
            } else if (newVal != oldVal) {
                userlogin.setPreIDAppend(newVal);
            }

            //console.log(userlogin.prefix);
        }*/
        },
        mounted() {
            /*
            if (searchParams.get("prefix") != false) {
                prefix = searchParams.get("prefix");
                console.log(prefix);
                console.log($("#organization select").data("key"));
             }
        */
        }
    });
}
if (document.getElementById("userprofile")) {
    const userprofile = new Vue({
        el: "#userprofile",
        data: {},
        methods: {},
        created: function() {},
        updated() {},
        watch: {},
        mounted() {}
    });
}
</script>
