/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the $avaScript scaffolding to fit your unique needs.
 */

//Here is global page
window.Vue.config.productionTip = false;
window.Vue.config.debug = true;
window.Vue.config.devTools = false;
window.Vue.config.silent = false;

//Init. LoadCom
function loadCom(vueTarget) {
    /* if (vueTarget.length >= 2) { */
    try {
        Vue.component(vueTarget, require('./components/' + vueTarget).default);
        //check index or default /
        //Loaded log at console.
        if (Vue.config.silent == false) {
            console.log('Components: [' + vueTarget + '] was loaded.');
        }
    } catch (ex) {
        //Skip log at console.
        if (Vue.config.silent == false) {
            console.warn('Components: [' + vueTarget + '] was skipped/undefined. \n' + ex);
        }
    }
    /*
    } else {
        if (Vue.config.silent == false) {
            console.warn('Components: [ This Page ] was skipped/undefined.');
        }
    }
    */

}

let comTarget = "";
//global Setup

Vue.prototype.config = {
    displaySearch: true,
    displayLanguage: true,
    displayNotice: true,
};



//Load
comTarget = 'header.vue';
//func. search at header
loadCom(comTarget);
comTarget = 'footer.vue';
//func. language at footer
loadCom(comTarget);
comTarget = 'exter.vue';
//func. language at search
loadCom(comTarget);

//Load By Page
//var uriData = window.location.pathname.split("/");
var uriData = window.location.pathname.split("/").filter(function (el) {
    return el != "";
});
//console.log(uriData);

if (uriData.length >= 2) {
    //comTarget = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
    comTarget = uriData.join('-') + ".vue";
} else {
    comTarget = uriData[0] + ".vue";

}
uriData = null;
//comTarget = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
loadCom(comTarget);
comTarget = null;

/*
const app = new Vue({
    el: '#app',
});
*/


$(".carousel-control-disabled").click(function (event) {
    $(event.target).parent('div').hide();

});
