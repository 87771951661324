<script>
var debugMessage = "";
const usersuvery = new Vue({
    el: "#dpt-account",
    data: {
        errorMSG: {},
    },
    methods: {

    },
    created: function () {
        if ($(".retMessage").text().length >= 1) {
            $(".readme").hide();
        }
    },
    updated() {
        if (Vue.config.debug == true) {
            console.log("Objects: " + errorMSG);
        }
    },
    watch: {
        // organize is organiaze ID
        /*
        step: (newVal, oldVal) => {
            if (newVal != oldVal) {
                email = newVal + "@me.mcu.edu.tw";
            }
        },
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {
        //this.getOrganizeList();
    },
});
</script>
