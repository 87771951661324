<script>
var debugMessage = "";
console.log("Survey Start MCU2022-1");

const usersuvery = new Vue({
    el: "#suvery_mcu2022",
    data: {
        quiz: {},
        step: 0,
        progBarWidth: "0",
        errorMSG: "",
        private1: false,
        private2: false,
    },
    methods: {
        prevStep: function () {
            //console.log("Objects: Prev");
            if (this.step == 0) {
                $("#qgroup0").html("");
                this.progBarWidth = 100;
                this.quiz["qz0"] = "0";
                $(".suvery-desc").addClass("text-center m-5");
                $(".suvery-desc").html("<h4>處理中...</h4>");
                $("#prevBtn").hide();
                $("#nextBtn").hide();
                axios
                    .post("/survey-post/mcu2022", this.quiz)
                    .then((response) => {
                        //console.log(response.data);

                        if (response.data.state == 1) {
                            $(".suvery-desc").html(
                                "<h4>" + response.data.message + "</h4>"
                            );
                        } else if (response.data.state == 2) {
                            $(".suvery-desc").html(
                                "<h4>" + response.data.message + "</h4>"
                            );
                        } else {
                            $(".suvery-desc").html(
                                "<h4>" + response.data.message + "</h4>"
                            );
                        }
                        setTimeout(
                            () => location.replace("/panel"),
                            3000
                        );
                    })
                    .catch(function (error) {
                        $(".suvery-desc").html(
                            "<h4>處理期間發生錯誤, 請聯絡 <a href='mailto://info@winhoe.com>畹禾客服</a></h4>"
                        );
                    });
            }
            if (this.step > 1) {
                this.step = this.step - 1;
                this.progBarWidth = 9 * this.step;
            }
            if (this.step == 1) {
                //$("#prevBtn").hide();
                this.progBarWidth = 9;
            }
            //hide all groups
            for (let index = 1; index <= 11; index++) {
                var target1 = "#qgroup" + index;
                $(target1).hide();

            }

            //$(".progress-bar").css("width", this.progBarWidth + "%").attr("aria-valuenow", this.progBarWidth).html(this.progBarWidth + "%");
            $(".progress-bar").css("width", this.progBarWidth + "%").attr("aria-valuenow", this.progBarWidth).html("");

            var open = "#qgroup" + this.step;
            $(open).show();
            console.warn("Step :" + this.step);
        },
        nextStep: function () {
            //console.log("Objects: Next");
            console.log("start: " + this.step);
            if (this.step < 12) {
                var flag = 0;
                switch (this.step) {
                    case 0:
                        //console.log("step: " + this.step);
                        $("#nextBtn").html("下一步");
                        $("#prevBtn").html("上一步");
                        $("#prevBtn").hide();
                        break;
                    case 1:
                        for (let index = 1; index <= 4; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            //console.log(target1 + " - " + target2 + " - " + $(target1).val());
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 2:
                        for (let index = 5; index <= 8; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 3:
                        for (let index = 9; index <= 13; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 4:
                        for (let index = 14; index <= 18; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 5:
                        for (let index = 19; index <= 23; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 6:
                        for (let index = 24; index <= 28; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 7:
                        for (let index = 29; index <= 33; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 8:
                        for (let index = 34; index <= 38; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 9:
                        for (let index = 39; index <= 43; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        break;
                    case 10:
                        for (let index = 44; index <= 48; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        $("#nextBtn").html("送出");
                        break;
                    //49~56
                    case 11:
                        for (let index = 49; index <= 52; index++) {
                            var target1 =
                                "input[name='qz" + index + "']:checked";
                            var target2 = "#qz" + index;
                            if ($(target1).val() >= 1) {
                                $(target2).removeClass("border border-danger");
                                this.quiz["qz" + index] = $(target1).val();
                            } else {
                                $(target2).addClass("border border-danger");
                                flag = 1;
                            }
                        }

                        if ($("input[name='qz51']:checked").val() == 2) {
                            if ($("input[name='qz56']").val().length >= 6) {
                                $("input[name='qz56']").removeClass("border border-danger");
                                this.quiz["qz56"] = $("input[name='qz56']").val();
                            } else {
                                $("input[name='qz56']").addClass("border border-danger");
                                flag = 1;
                            }
                        }
                        if ($("input[name='qz52']:checked").val() == 2) {
                            if ($("input[name='qz53']").val().length >= 2) {
                                $("input[name='qz53']").removeClass("border border-danger");
                                this.quiz["qz53"] = $("input[name='qz53']").val();
                            } else {
                                $("input[name='qz53']").addClass("border border-danger");
                                flag = 1;
                            }
                            if ($("input[name='qz54']").val().length >= 8) {
                                $("input[name='qz54']").removeClass("border border-danger");
                                this.quiz["qz54"] = $("input[name='qz54']").val();
                            } else {
                                $("input[name='qz54']").addClass("border border-danger");
                                flag = 1;
                            }
                            if ($("input[name='qz55']").val().length >= 8) {
                                $("input[name='qz55']").removeClass("border border-danger");
                                this.quiz["qz55"] = $("input[name='qz55']").val();
                            } else {
                                $("input[name='qz55']").addClass("border border-danger");
                                flag = 1;
                            }
                        }



                        break;
                    default:
                        location.replace("/doSurvey");
                        return 0;
                }

                if (flag == 0) {
                    $("#nextBtn").show();
                    //$("#prevBtn").show();
                    //hide all groups
                    for (let index = 0; index <= 11; index++) {
                        var target1 = "#qgroup" + index;
                        $(target1).hide();

                    }
                    this.progBarWidth = 9 * this.step;
                    if (this.step == 0) {
                        $(".progress-bar").css("width", "5%").attr("aria-valuenow", 5).html("");
                    } else {
                        //$(".progress-bar").css("width", this.progBarWidth + "%").attr("aria-valuenow", this.progBarWidth).html(this.progBarWidth + "%");
                        $(".progress-bar").css("width", this.progBarWidth + "%").attr("aria-valuenow", this.progBarWidth).html("");
                    }

                    this.step = this.step + 1;
                    //save
                    localStorage.setItem("doSurveyStep", this.step)
                    //read
                    //localStorage.getItem("userName") // qct
                    var open = "#qgroup" + this.step;
                    $(open).show();
                    if (this.step == 12) {
                        $(".suvery-desc").addClass("text-center m-5");
                        $(".suvery-desc").html("<h4>上傳中請稍候...</h4>");
                        $("#prevBtn").hide();
                        $("#nextBtn").hide();
                        //console.log(this.quiz);
                        this.quiz["qz0"] = 1;
                        axios
                            .post("/survey-post/mcu2022", this.quiz)
                            .then((response) => {
                                //console.log(response.data);

                                if (response.data.state == 1) {
                                    $(".suvery-desc").html(
                                        "<h4>" + response.data.message + "</h4>"
                                    );
                                } else if (response.data.state == 2) {
                                    $(".suvery-desc").html(
                                        "<h4>" + response.data.message + "</h4>"
                                    );
                                } else {
                                    $(".suvery-desc").html(
                                        "<h4>" + response.data.message + "</h4>"
                                    );
                                }
                                setTimeout(
                                    () => location.replace("/panel"),
                                    5000
                                );
                            })
                            .catch(function (error) {
                                $(".suvery-desc").html(
                                    "<h4>處理期間發生錯誤, 請聯絡 <a href='mailto://info@winhoe.com>畹禾客服</a></h4>"
                                );
                            });
                    }
                }
            }

            console.warn("Step :" + this.step);
        },
        disPriv1: function (priVal) {
            this.private1 = priVal;
        },
        disPriv2: function (priVal) {
            this.private2 = priVal;
        }
    },
    created: function () {
        //step = 0 with hide anothers

        $("#nextBtn").show();
        $("#prevBtn").show();
        //hide all groups
        for (let index = 0; index <= 11; index++) {
            var target1 = "#qgroup" + index;
            $(target1).hide();

        }
        $("#qgroup0").show();
        $("#exter").empty();

    },
    updated() {

        /*
        if (Vue.config.debug == true) {
            if (errorMSG !== underfined)
                console.log("Objects: " + errorMSG);
        }
        */
    },
    watch: {
        // organize is organiaze ID
        /*
        step: (newVal, oldVal) => {
            if (newVal != oldVal) {
                email = newVal + "@me.mcu.edu.tw";
            }
        },
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {
        //this.getOrganizeList();
    },
});
</script>
