<script>
var debugMessage = "";
const moment = require("moment");
//const html2canvas = require("html2canvas");
//const jspdf = require("jspdf");

//const jspdf = require('jspdf')
//const html2canvas = require('html2canvas')
/*
import moment from "moment";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
*/

const guide = new Vue({
el: "#app",
props: [],
data: {
    returnMode: "",
    purchaseType: "steps",
    purchase: "",
    mgroup: "",
    contactName: "",
    contactSID: "",
    contactPhone: "",
    students: [{ studentId: "", studentName: "", group: "", book: "" }],
    createDate: "",
    setUsers: 1,
    successMessage: null,
    errorMessage: null,
    downloadID: null,
    isSubmitting: false,
    alert711: 0,
    reqSID: null,
    reqOID: null,
},
methods: {
    /*
        purchase: function (priVal) {
            //console.log(priVal);
            window.location.href = '/guide/' + priVal;
                return;
        },
        */
    handleTableClass(event) {
    //console.log('Clicked Element:', event.target);
    if (event.target.tagName === "TD") {
        this.mgroup = event.target.textContent;
        this.updateGroup();
        $(".modal").modal("hide");
    }
    },
    addStudent() {
    if (this.students.length >= 33 && this.alert711 === 0) {
        alert("便利商店繳款上限為2萬元，建議將訂單拆分為兩筆。");
        this.alert711 = 1;
        return;
    }
    if (this.students.length >= 50) {
        alert("ATM 單次轉帳上限為 3萬元，請務必注意。");
        return;
    }

    this.students.push({
        studentId: "",
        studentName: "",
        group: "",
        book: "",
    });
    },
    removeStudent(xIndex) {
    switch (this.purchaseType) {
        case "term1":
        break;
        case "term2":
        break;
        case "termU":
        if (Object.keys(this.students).length > 1) {
            this.students.splice(xIndex, 1);
        } else {
            alert("個人購買/重補修，至少需要 1個班級以上。");
        }
        break;
        case "termX":
        if (Object.keys(this.students).length > 10) {
            this.students.splice(xIndex, 1);
        } else {
            alert("團體購買，至少需要 10個人以上。");
        }
        break;
        case "preview":
        break;
        default:
        break;
    }
    },
    updateGroup() {
    this.students.forEach((student) => {
        if (student.group === "") {
        student.group = this.mgroup;
        }
    });
    },
    updateSID() {
    this.students.forEach((student) => {
        student.studentId = this.contactSID;
    });
    },
    updateName() {
    this.students.forEach((student) => {
        student.studentName = this.contactName;
    });
    },
    returnPreview() {
    this.createDate = moment().format("YYYYMMDDHHmm");
    this.purchaseType = this.purchase;
    this.errorMessage = null;
    this.successMessage = null;
    },
    genPreview() {
    event.preventDefault();
    // 在此處向伺服器提交帖子資料
    this.createDate = moment().format("YYYYMMDDHHmm");
    // 在這裡可以執行 POST 請求或其他處理
    // 示例中只是簡單地將預覽模式切換為 true
    switch (this.purchaseType) {
        case "term1":
        this.purchase = this.purchaseType;
        this.purchaseType = "preview";
        break;
        case "term2":
        this.purchase = this.purchaseType;
        this.purchaseType = "preview";
        break;
        case "termU":
        this.purchase = this.purchaseType;
        this.purchaseType = "preview";
        break;
        case "termX":
        /*
                    if (Object.keys(this.students).length <10 ){
                        alert("團體購買，至少需要 10個人以上。");
                        return;
                    }
                    */
        this.students.forEach((student) => {
            student.group = mgroup.value;
        });
        this.purchase = this.purchaseType;
        this.purchaseType = "preview";
        break;
        case "preview":
        break;
        default:
        break;
    }
    //console.log("data:", this.students);
    },
    saveData() {
        document.getElementById("sendSpinner").classList.remove("d-none");

    // 檢查是否已經在提交中
    if (this.isSubmitting) {
        return;
    }
    // 設置提交狀態
    this.isSubmitting = true;
    // 獲取 CSRF 令牌
    const csrfToken = document.head.querySelector(
        'meta[name="csrf-token"]'
    ).content;

    // 將 CSRF 令牌新增到 Axios 要求標頭中
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    // 傳送 POST 請求
    axios
        .post("/api/order/store", {
        // 這裡新增要傳送的資料
        oid: this.createDate,
        type: this.purchase,
        group: this.mgroup,
        name: this.contactName,
        stuid: this.contactSID,
        phone: this.contactPhone,
        orders: this.students,
        })
        .then((response) => {
        // 處理成功響應
        this.successMessage = response.data.message;
        this.errorMessage = null;
        this.downloadID = response.data.downloadID;
        // 重設表單或執行其他操作
        })
        .catch((error) => {
        // 處理錯誤響應
        if (
            error.response &&
            error.response.data &&
            error.response.data.error
        ) {
            this.errorMessage = error.response.data.error;
        } else {
            this.errorMessage = "發生了一個錯誤，請重試。";
        }
        })
        .finally(() => {
        // 無論成功或失敗，都重設提交狀態
        document.getElementById("sendSpinner").classList.add("d-none");
        this.isSubmitting = false;
        });
    },
    reDownload() {
        if (this.reqSID === '' || this.reqOID === '' || (this.reqSID && this.reqSID.length < 8) || (this.reqOID && this.reqOID.length < 12)) {
            alert("請檢查學號或訂單編號格式是否正確");
            return;
        }

        // 產生下載連結的 URL
        const url = `/guide/downloads/${this.reqSID}/${this.reqOID}`;

        // 使用 Axios 來發送 GET 請求並觸發檔案下載
        axios.get(url, { responseType: 'blob' })
            .then((response) => {
                // 獲取檔案名稱 (從後端的 Content-Disposition header 或 URL 中獲取)
                //const disposition = response.headers["content-disposition"];
                //const filename = disposition ? disposition.split("filename=")[1].split(";")[0] : `${this.reqSID}-${this.reqOID}.docx`;
                const disposition = response.headers["content-disposition"];
                let filename = "document.docx";  // 預設文件名
                if (disposition && disposition.indexOf('filename=') !== -1) {
                    let matches = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (matches != null && matches[1]) {
                        // 提取文件名，並去掉文件路徑（若存在）
                        filename = matches[1].replace(/['"]/g, ''); // 去掉引號
                        filename = filename.split('/').pop(); // 如果是完整路徑，則僅保留最後的文件名
                    }
                }

                // 創建 Blob 並生成下載鏈接
                const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();

                // 移除鏈接
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);  // 立即銷毀對象 URL
            })
            .catch(error => {
                // 處理錯誤
                console.error("下載失敗", error);
                alert("無效的學號或訂單編號，無法取得檔案。");
            });
    },
    checkOrder(){

    },
    genDOCnSave() {
        // 顯示下載中的圖示
        document.getElementById("docSpinner").classList.remove("d-none");
    axios
        .post(
        "/guide/download",
        {
            // 這裡新增要傳送的資料
            stuid: this.contactSID,
            dlid: this.downloadID,
            filetype: "doc",
        },
        {
            responseType: "blob", // 明確指定響應類型為 blob
        }
        )
        .then((response) => {
        const disposition = response.headers["content-disposition"];
        const filename = disposition
            ? disposition.split("filename=")[1].split(";")[0]
            : "document.docx";

        // 處理成功響應
        const blob = new Blob([response.data], {
            type:
            response.headers["content-type"] || "application/octet-stream",
        });

        // 建立一個連結
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // 設定下載檔案名稱
        a.style.display = "none";

        // 將 <a> 元素新增到頁面
        document.body.appendChild(a);

        // 模擬點選 <a> 元素
        a.click();

        // 釋放 URL 和移除 <a> 元素
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        document.getElementById("docSpinner").classList.add("d-none");
        })
        .catch((error) => {
        console.error("檔案下載失敗：", error);
        document.getElementById("docSpinner").classList.add("d-none");
        });
    },
    genPDFnSave() {
        // 顯示下載中的圖示
        document.getElementById("pdfSpinner").classList.remove("d-none");
    axios
        .post(
        "/guide/download",
        {
            // 這裡新增要傳送的資料
            stuid: this.contactSID,
            dlid: this.downloadID,
            filetype: "pdf",
        },
        {
            responseType: "blob", // 明確指定響應類型為 blob
        }
        )
        .then((response) => {
        const disposition = response.headers["content-disposition"];
        const filename = disposition
            ? disposition.split("filename=")[1].split(";")[0]
            : "document.pdf";

        // 處理成功響應
        const blob = new Blob([response.data], {
            type:
            response.headers["content-type"] || "application/octet-stream",
        });

        // 建立一個連結
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // 設定下載檔案名稱
        a.style.display = "none";

        // 將 <a> 元素新增到頁面
        document.body.appendChild(a);

        // 模擬點選 <a> 元素
        a.click();

        // 釋放 URL 和移除 <a> 元素
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        document.getElementById("pdfSpinner").classList.add("d-none");
        })
        .catch((error) => {
        console.error("檔案下載失敗：", error);
        document.getElementById("pdfSpinner").classList.add("d-none");
        });
    },
    gen711nSave() {
        // 顯示下載中的圖示
        document.getElementById("711Spinner").classList.remove("d-none");
    axios
        .post(
        "/guide/download",
        {
            // 這裡新增要傳送的資料
            stuid: this.contactSID,
            dlid: this.downloadID,
            filetype: "711",
        },
        {
            responseType: "blob", // 明確指定響應類型為 blob
        }
        )
        .then((response) => {
        const disposition = response.headers["content-disposition"];
        const filename = disposition
            ? disposition.split("filename=")[1].split(";")[0]
            : "document.pdf";

        // 處理成功響應
        const blob = new Blob([response.data], {
            type:
            response.headers["content-type"] || "application/octet-stream",
        });

        // 建立一個連結
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // 設定下載檔案名稱
        a.style.display = "none";

        // 將 <a> 元素新增到頁面
        document.body.appendChild(a);

        // 模擬點選 <a> 元素
        a.click();

        // 釋放 URL 和移除 <a> 元素
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        document.getElementById("711Spinner").classList.add("d-none");
        })
        .catch((error) => {
        console.error("檔案下載失敗：", error);
        document.getElementById("711Spinner").classList.add("d-none");
        });
    },
    /*
        PE34: function (priVal) {
            console.log("PE34");
        },
        PE56: function (priVal) {
            console.log("PE56");
        },
        PE78: function (priVal) {
            console.log("PE78");
        }
        */
    changeType(newVal) {
    this.purchase = "";
    this.mgroup = "";
    this.contactName = "";
    this.contactSID = "";
    this.contactPhone = "";
    this.students = [{ studentId: "", studentName: "", group: "", book: "" }];
    this.errorMessage = null;
    this.successMessage = null;
    this.downloadID = null;
    switch (newVal) {
        case "term1":
        this.setUsers = 1;
        this.students = [
            {
            studentId: "",
            studentName: "",
            group: "",
            book: 350,
            },
        ];
        break;
        case "term2":
        this.setUsers = 1;
        this.students = [
            {
            studentId: "",
            studentName: "",
            group: "",
            book: 650,
            },
        ];
        break;
        case "termU":
        this.setUsers = 1;
        break;
        case "termX":
        this.setUsers = 10;
        for (var idx = 1; idx < this.setUsers; idx++) {
            this.addStudent();
        }
        break;
        case "check":
        window.location.href = "/account";
        break;
        case "checkOrDL":
        break;
        default:
        window.location.href = "/guide";
        break;
    }
    },
},
created: function () {
    this.purchaseType = "steps";
},
updated: function () {
    //console.log('updated');
},
computed: {
    totalBookPrice() {
    return this.students.reduce(
        (total, student) => total + parseInt(student.book),
        0
    );
    },
    /*
        syncedStudents() {
            return this.students.map(student => ({
                group: this.mgroup,
            }));
        },
        */
},
watch: {
    /*
        purchaseType(newVal, oldVal) {
            if (newVal.length !== oldVal){
                this.purchase= "";
                this.mgroup= "";
                this.contactName= "";
                this.contactSID="";
                this.contactPhone= "";
                this.students= [{ studentId:"", studentName: "", group: "", book: "" }];

                switch (this.purchaseType) {
                case "term1":
                    this.students= [{ studentId:"", studentName: "", group: "", book: 350 }];
                    break;
                case "term2":
                    this.students= [{ studentId:"", studentName: "", group: "", book: 580 }];
                    break;
                case "termU":

                    break;
                case "termX":

                    break;
                case "preview":
                    break;
                default:
                    break;
                }
            }
        },
        */
    // organize is organiaze ID
    /*
        ,
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
},
mounted() {
    //this.getOrganizeList();
    $("#startTip").modal("show");
},
});
</script>

