<script>
const header = new Vue({
    el: "#header",
    data: {
        noticeIsShow: true
    },
    methods: {},
    created: function() {
        this.noticeIsShow = this.config.displayNotice;
    },
    updated() {},
    watch: {},
    mounted() {}
});
</script>
