<script>
var debugMessage = "";

//const jspdf = require('jspdf')
//const html2canvas = require('html2canvas')
/*
import moment from "moment";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
*/

const useraccount = new Vue({
    el: "#useraccount",
    props: [],
    data: {
        studentID: "",
        successMessage: null,
        errorMessage: null,
        downloadID: null,
    },
    methods: {
        changeTAB(newVal) {
            this.studentID;
            this.successMessage = null;
            this.errorMessage = null;
            this.downloadID = null;
            switch (newVal) {
                case "term1":
                    this.setUsers = 1;
                    this.students = [
                        {
                            studentId: "",
                            studentName: "",
                            group: "",
                            book: 350,
                        },
                    ];
                    break;
                case "term2":
                    this.setUsers = 1;
                    this.students = [
                        {
                            studentId: "",
                            studentName: "",
                            group: "",
                            book: 580,
                        },
                    ];
                    break;
                case "termU":
                    this.setUsers = 1;
                    break;
                case "termX":
                    this.setUsers = 10;
                    for (var idx = 1; idx < this.setUsers; idx++) {
                        this.addStudent();
                    }
                    break;
                case "check":
                    window.location.href = '/account';
                    break;
                default:
                    window.location.href = '/guide';
                    break;
            }
        },
    },
    created: function () {

    },
    updated: function () {
        //console.log('updated');
    },
    computed: {

        /*
        syncedStudents() {
            return this.students.map(student => ({
                group: this.mgroup,
            }));
        },
        */
    },
    watch: {
        /*
        purchaseType(newVal, oldVal) {
            if (newVal.length !== oldVal){
                this.purchase= "";
                this.mgroup= "";
                this.contactName= "";
                this.contactSID="";
                this.contactPhone= "";
                this.students= [{ studentId:"", studentName: "", group: "", book: "" }];

                switch (this.purchaseType) {
                case "term1":
                    this.students= [{ studentId:"", studentName: "", group: "", book: 350 }];
                    break;
                case "term2":
                    this.students= [{ studentId:"", studentName: "", group: "", book: 580 }];
                    break;
                case "termU":

                    break;
                case "termX":

                    break;
                case "preview":
                    break;
                default:
                    break;
                }
            }
        },
        */
        // organize is organiaze ID
        /*
        ,
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {

    },
});
</script>
