<script>
const defData = [
    {
        id: 0,
        prefix: "WHC",
        abbr: "畹禾公司",
        en_name: "The WinHoe Company",
        signin_control: 1
    },
    {
        id: 3,
        prefix: "TW_NTU",
        abbr: "臺灣大學",
        en_name: "National Taiwan University",
        signin_control: 1
    }
];
debugMessage = "";
let searchParams = new URLSearchParams(window.location.search);
const userlogin = new Vue({
    el: "#userlogin",

    data: {
        /**
         *? The Global Vars from Form
         */
        tmp: {},
        organizes: {},
        preid: "",
        prefix: "",
        loginMethod: "",
        remember: false,
        username: "",
        password: "",
        captcha: "",
        errorVal: {
            username: 0,
            password: 0
        },
        errorMSG: {}
    },
    methods: {
        login: function() {
            //valid
            userlogin.errorMSG = {};
            const regex_prefix = /^[A-Z0-9_]{3,10}$/gs;
            if (regex_prefix.test(userlogin.prefix)) {
                userlogin.domain = userlogin.prefix.substring(
                    userlogin.prefix.lastIndexOf("_") + 1
                );
                userlogin.domain = userlogin.domain.toLowerCase();

                delete userlogin.errorMSG.prefix;
            } else {
                userlogin.errorMSG.prefix = "Prefix 無效!";
            }
            const regex_preid = /^[\d]{1,5}$/gs;
            if (regex_preid.test(userlogin.preid)) {
                delete userlogin.errorMSG.preid;
            } else {
                userlogin.errorMSG.preid = "PreID 無效!";
            }
            const regex_username = /^[a-z0-9]{4,16}$/gs;
            const regex_learnerid = /^[a-z0-9_]{5,16}$/gs;
            const regex_email = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gs;

            switch (userlogin.loginMethod) {
                case 0:
                    if (regex_username.test(userlogin.username)) {
                        userlogin.errorVal.username = 0;
                        delete userlogin.errorMSG.username;
                    } else {
                        userlogin.errorVal.username = "true";
                        userlogin.errorMSG.username = "無效的帳號!";
                    }
                    break;
                case 1:
                    if (regex_learnerid.test(userlogin.username)) {
                        userlogin.errorVal.username = 0;
                        delete userlogin.errorMSG.username;
                    } else {
                        userlogin.errorVal.username = "true";
                        userlogin.errorMSG.username = "無效的學號!";
                    }
                    break;
                case 2:
                    if (regex_email.test(userlogin.username)) {
                        userlogin.errorVal.username = 0;
                        delete userlogin.errorMSG.username;
                    } else {
                        userlogin.errorVal.username = "true";
                        userlogin.errorMSG.username = "無效的信箱!";
                    }
                    break;
                default:
                    break;
            }

            const regex_password = /^[A-Za-z0-9`~!@#$%^&*-_=+,<>?]{5,20}$/gs;
            if (regex_password.test(userlogin.password)) {
                userlogin.errorVal.password = 0;
                delete userlogin.errorMSG.password;
            } else {
                userlogin.errorVal.password = "true";
                userlogin.errorMSG.password = "無效的密碼!";
            }

            const captch_password = /^[A-Za-z0-9]{4,10}$/gs;
            if (captch_password.test(userlogin.captcha)) {
                userlogin.errorVal.captcha = 0;
                delete userlogin.errorMSG.captcha;
            } else {
                userlogin.errorVal.captcha = "true";
                userlogin.errorMSG.captcha = "無效的驗證碼!";
            }
            //console.log(Object.keys(userlogin.errorMSG).length);
            if (Object.keys(userlogin.errorMSG).length !== 0) {
                //userlogin.errorMSG.flag =
                "setting is invalid! (" +
                    Object.keys(userlogin.errorMSG).length +
                    ")";

                return;
            } else {
                axios
                    .post("/user/login", {
                        type: "ajax",
                        username: this.username,
                        password: this.password,
                        loginMethod: this.loginMethod,
                        prefix: this.prefix,
                        preid: this.preid,
                        domain: this.domain,
                        remember: this.remember,
                        captcha: this.captcha
                    })
                    .then(response => {
                        //console.log(response.data);

                        if (response.data.auth == true) {
                            userlogin.router.push(response.data.intended);
                        } else {
                            $("#captcha").click();
                        }
                    })
                    .catch(function(error) {
                        if (typeof error.response !== "undefined") {
                            if (error.response.status == 401) {
                                userlogin.errorMSG = error.response.data.msg;
                                $("#captcha").click();
                            } else if (error.response.status == 429) {
                                userlogin.errorMSG = {
                                    limit: error.response.data.message
                                };
                            } else {
                                $("#captcha").click();
                            }
                        } else {
                            //userlogin.router.push("/home");
                            location.replace("/home");
                            //userlogin.errorMSG = error.response.data.status;
                            //console.log(error.response.status);
                            // console.log(userlogin.errorMSG);
                            // console.log(error.response.statusText);
                        }
                    });
                return;
            }
        },

        setLoginMethod: function(val) {
            userlogin.loginMethod = val;
        },
        setPreIDAppend: function(newVal) {
            userlogin.preid = newVal;
            //bug fix for WHC id is 0
            if (newVal == 0) {
                newVal = "00";
            }
            axios
                .get("/api/org/signin/" + newVal)
                .then(function(response) {
                    //when user change organize and get result
                    userlogin.loginMethod = parseInt(
                        response.data[0].signin_method
                    );
                    userlogin.prefix = response.data[0].prefix;

                    debugMessage = "Update method successed. [setPreIDAppend]";
                })
                .catch(function(error) {
                    //userlogin.currentOrganize = {};
                    debugMessage =
                        "Update failed. [setPrefixAppend].\r\nloaded default data.\r\n";
                });
        }
        /*
        getOrganizeList: function(newVal = null) {
            let apiURL = "";
            if (newVal == null) {
                apiURL = "/api/org/signin";
            } else {
                apiURL = "/api/org/signin/" + newVal;
            }
            axios
                .get(apiURL, {})
                .then(function(response) {
                    if (response.data.length == 0) {
                        userlogin.organizes = defData;

                        debugMessage =
                            "Update failed. [getOrganizeLists],\r\ndata empty.\r\nloaded default data.\r\n";
                        //console.warn(debugMessage);
                    } else {
                        userlogin.organizes = response.data;
                        debugMessage =
                            "Update successed. [getOrganizeLists]\r\n";
                    }
                    //if element count equal 1 , auto to bind element
                    if (userlogin.organizes.length == 1) {
                        userlogin.preid = userlogin.organizes[0].id;
                    }
                })
                .catch(function(error) {
                    userlogin.organizes = defData;
                    debugMessage =
                        "Update failed. [getOrganizeLists].\r\nloaded default data.\r\n";
                    //console.warn(error);
                });
            return;
        }*/
    },

    created: function() {
        /*
        Method For Hash Tag
        if (window.location.hash) {
            var prefix = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            this.getOrganizeList(prefix);
        } else {
            this.getOrganizeList();
        }
        */
        //this.getOrganizeList();
    },

    updated() {
        if (Vue.config.debug == true) {
            console.log("Objects: " + debugMessage);
        }
    },
    watch: {
        preid: (newVal, oldVal) => {
            if (oldVal == "") {
                userlogin.setPreIDAppend(newVal);
            } else if (newVal != oldVal) {
                userlogin.setPreIDAppend(newVal);
            }

            //console.log(userlogin.prefix);
        }
    },
    mounted() {
        /*
            if (searchParams.get("prefix") != false) {
                prefix = searchParams.get("prefix");
                console.log(prefix);
                console.log($("#organization select").data("key"));
             }
        */

        if ($("#organize option").length == 2) {
            $("#organize")[0].selectedIndex = 1;
            this.preid = $("#organize").val();
        }
    }
});
</script>
