<script>
let debugMessage = "";

const purchase = new Vue({
    el: "#thepurchase",
    data: {
        fullpack: 0,
        halfpack: 0,
        total: 0,
        modalTitle: "核對訂單",
        modalContent: "price is",
        errorMSG: ""
    },
    methods: {
        getTotal: function() {
            this.total = this.fullpack * 450 + this.halfpack * 300;
            //console.log(this.total);
        },
        invoice: function() {
            if (this.fullpack >= 70) {
                this.errorMSG = "無效的訂單, 請檢查購買數量 (3)";
            } else this.errorMSG = "";
            if (this.fullpack <= -1) {
                this.errorMSG = "無效的訂單, 請檢查購買數量 (4)";
            } else {
                this.errorMSG = "";
            }
            if (this.halfpack >= 25) {
                this.errorMSG = "無效的訂單, 請檢查購買數量 (5)";
            } else if (this.halfpack <= -1) {
                this.errorMSG = "無效的訂單, 請檢查購買數量 (6)";
            } else {
                this.errorMSG = "";
            }

            if (this.total <= 0) {
                this.errorMSG = "無效的訂單, 請檢查訂單金額 (1)";
            } else if (this.total > 35000) {
                this.errorMSG = "無效的訂單, 請檢查訂單金額 (2)";
            } else {
                this.errorMSG = "";
            }
            if (this.errorMSG.length != 0) {
                $("#mmodalTitle").text("錯誤");
                $("#mmodalContent").text(this.errorMSG);
                $("#mmodal").modal("show");
            } else {
                $("#purchase").submit();
            }
        },
        invoiceCancel: function(invoice) {
            var delInvoice = confirm("您確定要取消訂單嗎?");
            if (invoice >= 1) {
                if (delInvoice) {
                    location.replace("/voidinvoice/" + invoice);
                }
            }
        }
    },
    created: function() {},
    updated() {
        /*
        if (Vue.config.debug == true) {
            console.log("Objects: " + debugMessage);
        }*/
    },
    watch: {
        total: (newVal, oldVal) => {
            console.log("Your choose is " + newVal);
        }
    },
    mounted() {
        /*
            if (searchParams.get("prefix") != false) {
                prefix = searchParams.get("prefix");
                console.log(prefix);
                console.log($("#organization select").data("key"));
             }
        */
    }
});
</script>
